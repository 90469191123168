@import url(../index.css);


.AboutPage{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 100px;
    margin-bottom: 180px;
}

.aboutImage{
    max-width: 308px;
  
}

.aboutImage img{
    width: 100%;
}

.aboutTitle{
    color: #091C23;
font-family: "font1";
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: normal;
position: absolute;
top: 180px;
}

.aboutgrd{
    color: #000;
text-align: center;
font-family: "font2";
font-size: 20px;
font-style: normal;
margin-top: 30px;
font-weight: 600;
line-height: normal;
}

.abotText{
    width: 80%;
    color: #000;
text-align: center;
font-family: Raleway;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.6px;
}

.roadmapSection{
    width: 100%;
    margin-bottom: 550px;
    max-height: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.roadmapSectionLeft {
    width: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;


}
.roadmapSectionright{
    width: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.roadmapSectionCenter{
    width: 20%;
    display: flex;
    justify-content: center;
}

.mappathDesign{
    border-radius: 23px;
background: #FFF;
box-shadow: 1px 5px 7px 0px rgba(0, 0, 0, 0.11) inset;
width: 19px;
height: 784px;
flex-shrink: 0;
position: relative;
}

.mappathDesigncenter{
    width: 49px;
    height: 49px;
    border-radius: 50%;
background: #FFF;
position: sticky;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
margin-left: -14px;
margin-top: 10px;
top: 40%;
}


@media only screen and (max-width: 650px) {
    .AboutPage{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 10px;
        margin-bottom: 90px;
    }
    .aboutTitle{
        font-size: 13vw;
    }

    .abotText{
        width: 90%;
        font-size: 16px;
        text-align: justify;
    }

  }



  @media only screen and (max-width: 1350px) {
    .roadmapSectionLeft {
        width: 40%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 10px;

    
    
    }
    .roadmapSectionright{
        width: 40%;
        display: flex;
        margin: 0 10px;
        justify-content: center;
        flex-direction: column;
    }
    
    .roadmapSectionCenter{
        width: 20%;
        display: flex;
        justify-content: center;
    }

  }


  @media only screen and (max-width: 700px) {
  .roadmapSectionCenter{
    position: absolute;    
  }

  .roadmapSection{
    width: 100%;
    margin-bottom: 200px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

  .roadmapSectionLeft {
    width: 95%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 10px;
    align-items: end;



}
.roadmapSectionright{
    width: 95%;
    display: flex;
    margin: 0 10px;
    flex-direction: column;
    justify-content: center;
    align-items: end;

}

.mappathDesign{
    height: 1200px;
}
  }

