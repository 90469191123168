@import url(../index.css);


.Titlebar {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 80px;
    z-index: 7;

}

.navlinknone{
    text-decoration: none;
    color: #091C23;

}

.homePage {
    width: 100%;
    height: 100vh;
}

.Titilename{
    color: #091C23;
font-family: "font1";
font-size: 96px;
font-style: normal;
font-weight: 400;
line-height: normal;
transition: all 1s;

}

.fieldname{
color: #091C23;
font-family: Raleway;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 15px;
transition: all 1s;

}

.iconLogo{
    width: 29px;
height: 29px;
margin: 15px 10px;
transition: all 1s;

}

.logosectionMain{
    display: flex;
    flex-direction: row;
}

.mainSection{
    width: 100%;
    height: 100vh;
    position: absolute;
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(50px);
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
}

.bgDesign{
    position: absolute;
    width: 100%;
    top: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;


}

.bgDesign img{
    width: 80%;
    position: relative;
    height: 80%;
}

.TitlebarItem {
    color: #091C23;
    font-family: "font1";
    cursor: pointer;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 50%;
    padding-top: 20px;
    padding-left: 30px;
    transition: all 1s;
    z-index: 7;


}

.resumeBtn {
    position: absolute;
    border-radius: 24px;
    right: 20px;
    top: 20px;
    cursor: pointer;
    border: 2px solid #000;
    width: 137px;
    height: 42px;
    color: #000;
    font-family: "font2";
    z-index: 7;

    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 1s;
    color: #091C23;
}

.resumeBtn:hover{
    background-color: #091C23;
    color: white;
    border: 2px solid #ffffff;

}





@media only screen and (max-width: 650px) {
    .TitlebarItem {
        font-size: 21px
    }

    .resumeBtn {
        width: 112px;
        height: 36px;
        font-size: 16px;
    }

}


@media only screen and (max-width: 750px) {
  .Titilename{
    font-size: 13vw;
  }
  .fieldname{
    font-size: 6vw;
    margin-top: 0;
  }

}


