

.PortfolioSection{
    margin-top: 60px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    position: relative;
}

.portfolioMain{
    display: flex;
    width: 100%;
    position: relative;
}

.imgAS1{
    position: relative;
    width:100%;
    cursor: pointer;
}

.bigportfoliobar{
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: center;
    margin-left: 0%;
transition: all 1s;
position: relative;
}


.portfolioMainas1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin: 20px;
    position: relative;
}

.selectPortfolio{
    width: 200px;
    height: 50px;
    background: rgba(0, 0, 0, 0.45);
    border-radius:50px;
    position: sticky;
    top: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
}

.selectPortfoliobtn{
    width: 10px;
    height: 10px;
    margin: 4px;
    border-radius: 10px;
    background-color: white;
    transition: all 0.6s;
    cursor: pointer;
}

.selectPortfoliobtn_act{
    width: 40px !important;
    transition: all 0.6s;
    

}

@media screen and (max-width: 1800px) {
    .imgAS1{
        width: 100%;
    }
  }

  @media screen and (max-width: 1500px) {
    .imgAS1{
        width: 100%;
    }
  }


@media screen and (max-width: 1100px) {
    .imgAS1{
        width: 100%;
    }
    .portfolioMain {
        display: flex;
        width: 80%;
    }
  }

@media screen and (max-width: 990px) {
    .imgAS1{
        width: 100%;
    }
    .portfolioMain {
        display: flex;
        width: 100%;
    }
    .portfolioMainas1{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 20px;
        position: relative;
    }
  }









