@import url(../index.css);

 .Contactus{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 200px;
 }

 .Contactus_main{
	width: 1100px;
	height: 600px;
	background-color: #31363F;
	border-radius: 20px;
	display: flex;
	flex-direction: row;
	transition: all 2s;
 }



 .Contactus_mainimg{
	width: 100%;
	border-radius: 20px 20px 20px 20px;
	border: none;
	height: 600px;
	z-index: 2;
	object-fit: cover;
	transition: all 2s;

 }

 .Contactus_mainimg-left{
	display: flex;
	flex-direction: column;
	padding: 20px;
	justify-content: center;
	align-items: center;
	width: 50%;
 }

 .Contactus_mainimgName{
    color: white;
font-family: "font1";
font-size: 50px;
margin-bottom: 10px;
font-style: normal;
text-align: center;
font-weight: 400;
line-height: normal;
width: 390px;

transition: all 1s;

}

.Contactus_mainimgpo{
	color: white;
font-family: "font2";
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: normal;
transition: all 1s;
margin-top: 0px;
}

.Contactus_mainimgEmail{
	color: white;
font-family: "font2";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
transition: all 1s;
margin-top: 5px;
}

.Contactus_mainimgBtn{
	font-family: "font2";
font-size: 20px;
color: white;
border: 1px solid white;
margin-top: 15px;
padding: 8px 35px ;
border-radius: 55px;
cursor: pointer;

}

@media screen and (max-width: 950px) {
	.Contactus_main{
		flex-direction: column;
		width: 95%;
		max-width: 590px;
	}
	.Contactus_mainimg{
		width: 100%;
		height: 50%;
	border-radius: 20px 20px 40px 40px;

	}

	.Contactus_mainimgName{
		width: 100%;
	}

	.Contactus_mainimg-left{
		width: 100%;
		height: 50%;
		padding: 0;
	}
  }