.card {
    width:200px;
    height:300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 36px;
    perspective: 2500px;
    margin-top: 410px;
    margin: 0 50px;
    overflow: visible;
    margin-bottom: 100px;
  }
  
  .cover-image {
    width: 100%;
    height: 100%;
    overflow: visible;
    object-fit: cover;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    background-color: rgb(241, 241, 241);


  }


  
  .wrapper {
    transition: all 0.5s;
    position: absolute;
    width: 100%;

    height:350px;
    overflow: visible;

    z-index: -1;
  }
  
  .card:hover .wrapper {
    transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
    box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    background-color: rgb(230, 230, 230);
    -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    border-radius: 20px;
  }

  .card:hover  .cover-image{
    visibility: hidden;
    
  }
  
  .wrapper::before,
  .wrapper::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 80px;
    transition: all 0.5s;
    position: absolute;
    left: 0;
    visibility: hidden;

  }
  .wrapper::before {
    top: 0;
    height: 100%;
    visibility: hidden;

    background-image: linear-gradient(
      to top,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
  }
  .wrapper::after {
    bottom: 0;
    opacity: 1;
    visibility: hidden;

    background-image: linear-gradient(
      to bottom,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
  }
  
  .card:hover .wrapper::before,
  .wrapper::after {
    opacity: 1;
    visibility: hidden;

  }
  
  .card:hover .wrapper::after {
    height: 120px;
    visibility: hidden;

  }
  .title {
    transition: transform 0.5s;
    margin-bottom: 16px;
    height: 20%;

  }
  .card:hover .title {
    transform: translate3d(0%, -50px, 100px);

  }
  
  .character {
    width: 115%;
    height: 300px;
    object-fit: cover;
    opacity: 0;
    transition: all 0.5s;
    position: absolute;

    z-index: -1;
  }
  

  
  .card:hover .character {
    opacity: 1;
    transform: translate3d(0%, -30%, 100px);
    
  }