@import url(../index.css);


.NavBar{
    position: fixed;
    bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 7;
    width: 100%;
}

.NavbarCard{
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    background: #091C23;
    backdrop-filter: blur(27.5px);
    height: 55px;
    padding: 7px;
}

.NavbarItem{
    color: #FFF;
font-family: "font3";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
padding: 0px 30px;
cursor: pointer;
transition: all 0.7s;
}

.topItem{
    color: #FFF;
font-family: "font3";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
padding: 0px 30px;
cursor: pointer;
transition: all 0.7s;
}
.NavbarItem:hover{
    color: #091C23;
    border-radius: 30px;
    background-color: rgb(255, 255, 255);
}

@media only screen and (max-width: 650px) {
    .NavbarItem{
        font-size: 13px;
        padding: 0px 11px;

    }

    .NavbarCard{
        height: 44px;
    }

    .topItem{
        padding: 0px 11px;

    }

  }