.cardSection{
    border-radius: 14px;
border: 1px dashed #FE9800;
background: #FFF;
max-width: 408px;
min-height: 208px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 15px;
flex-shrink: 0;
  }

.titleBAr{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.titlename{
    color: #0A1C23;
font-family: "font1";
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 55%;

}

.companyName{
    color: #000;
    width: 100%;
font-family: "font2";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.dateSection{
    display: flex;
    justify-content: flex-end;
    width: 40%;
    color: #0A1C23;
font-family: "font2";
font-size: 15px;
font-style: normal;
font-weight: 600;
margin-top: 10px;
line-height: normal;
}

.textSection{
    color: #000;
text-align: justify;
font-family: "font2";
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
}


@media only screen and (max-width: 700px) {
 .cardSection{
    margin-top: 30px !important;
    width: 70% !important;
 }

 .titleBAr{
    flex-direction: column;
}

.titlename{
width: 100%;
}

.dateSection{
    justify-content: flex-start;
    width: 100%;
}
    }