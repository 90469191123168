@import url(../index.css);

.App{
    font-family: "font1";
}

.WorkcardSection{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 130px;
    margin-bottom: 50px;
}

.Portfolioname{
    color: #091C23;
font-family: "font1";
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
}